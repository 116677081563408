import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage} from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/seo"
import SideHeaderPage from "../components/SideHeaderPage"
import Grid, { GridItem } from "../components/Grid"



const ChartPage = () => {
  const data = useStaticQuery(graphql`
    query ChartPageQuery {
        allWpChart(filter: {status: {eq: "publish"}}) {
            edges {
              node {
                slug
                id
                title
                featuredImage {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData
                      }
                    }
                    filename
                  }
                }
              }
            }
    }
      
    }
    
  `)

  
  return (
    <Layout>
      <Seo title="Charts" />
      <SideHeaderPage pageTitle={`Charts`}>


      <Grid gridCols={`grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4`}>
          {data.allWpChart.edges.map(chart => {

          const imageData = getImage(chart.node.featuredImage?.node?.localFile)

          if (!imageData) return null

              return (
                  <GridItem key={chart.node.id}>
                      <Link to={`/chart/${chart.node.slug}`} className={`mb-3 md:mb-4 block`}>
                          <GatsbyImage 
                              image={imageData}
                              alt={chart.node.featuredImage.node.altText || chart.node.title}
                              className={`item-shadow`}
                          />
                      </Link> 
                      <h3 className={`font-bold mb-2 text-base leading-[115%] sm:text-[1.38rem]`}>
                      <Link to={`/chart/${chart.node.slug}`}>{chart.node.title}</Link>
                      </h3>
                  </GridItem>
              )
          })}
      </Grid>
      </SideHeaderPage>
      
 
    </Layout>
  )
}

export default ChartPage
