import React from 'react'

const Grid = props => {

    const { className, gridCols, children } = props;

    let gridClassName = null;
    if (className) gridClassName = className;

    return (

        <div className={gridClassName}>
            <div className={`grid gap-3 sm:gap-5 lg:gap-7 xl:gap-10 ${gridCols}`}>
                {children}
            </div>
        </div>
    )
}

export default Grid 

Grid.defaultProps = {
    gridCols: `grid-cols-2 md:grid-cols-3 lg:grid-cols-4`
}

export const GridItem = props => {

    const { className, children } = props;

    let gridItemClassName = null;
    if (className) gridItemClassName = className;

    return (
        <div className={gridItemClassName}>
            <div>
                {children}
            </div>
        </div>
    )
}