import React from 'react'
import Container from './Container';
import { PageTitle } from './Titles';
import { sideheaderWrapper, headerBlock, feedBlock, sideTitle, sideTitleWrapper } from '../styles/sideheader.module.scss'

export default function SideHeaderPage(props) {
    const { pageTitle, children } = props;

    // const titleStyle = {
    //     writingMode: 'sideways-lr',
    //     fontWeight: '500',
    //     marginLeft: '-1.75rem'
    //   }
    
    if (!pageTitle) return null;

    return (
        <Container className={`py-12 sm:py-16 md:py-20 md:pt-16 lg:min-h-[80vh]`} size={`lg`}>
        <div className={sideheaderWrapper}>
            <div className={headerBlock}>
                <PageTitle className={sideTitleWrapper}>
                        <span className={sideTitle}>{pageTitle}</span>
                </PageTitle>
            </div>
            <div className={feedBlock}>
                <PageTitle className={`mb-7 md:mb-0 md:hidden`} size={`text-4xl`}>{pageTitle}</PageTitle>
                    {children}
            </div>
            
        </div>
        </Container>
    )
}